<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div(v-if="sailorDocument.behavior") {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`, { number: sailorDocument.number_document || sailorDocument.number }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="sailorDocument.behavior.viewInfoBlock && !$route.query.viewEditBlock" :sailorDocument="sailorDocument" :comments="comments")
    Edit(v-else-if="sailorDocument.behavior.viewEditBlock || $route.query.viewEditBlock" :sailorDocument="sailorDocument" actionType="edit")
    EditStatus(v-else-if="sailorDocument.behavior.viewEditStatusBlock" :sailorDocument="sailorDocument")
    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.files"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="checkTypeDocument ? removeFileQualification : removeFileProof"
      :editFile="checkTypeDocument ? updateFileQualification : updateFileProof"
      isShowEdit
      )
    //VerificationSteps(v-else-if="sailorDocument.behavior.viewVerificationStepsBlock" :sailorDocument="sailorDocument" getFunctionName="getQualificationDocuments")
v-progress-linear(v-else indeterminate)
</template>

<script>

import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapActions, mapGetters } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  components: {
    Info: () => import('@/views/Sailor/SailorQualification/Info.vue'),
    Edit: () => import('@/views/Sailor/SailorQualification/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorQualification/EditStatus.vue'),
    VerificationSteps: () => import('@/components/atoms/VerificationSteps/VerificationSteps.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'qualification',
      comments: [],
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('qualification', 'edit', this.sailorDocument) && !this.sailorDocument.signatures_info.length,
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('qualification', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('qualification', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        // {
        //   id: 7,
        //   name: ' mdi-folder-eye-outline',
        //   action: () => viewDetailedComponent(this.sailorDocument, 'viewVerificationStepsBlock'),
        //   checkAccess: () => this.sailorDocument.status_document.id === 34 && checkAccess('qualification', 'verificationSteps', this.sailorDocument),
        //   color: '#dd7137ab',
        //   tooltip: 'documentIconControl.verificationSteps'
        // },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('qualification', 'delete', this.sailorDocument),
          color: '#F73E07',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'qualification-documents', params: { id: this.$route.params.id } }),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      isLoading: state => state.sailor.isLoading,
      sailorDocument: state => state.sailor.qualificationById
    }),
    checkTypeDocument () {
      return this.$route.name === 'qualification-documents-info'
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getQualificationDocumentsByID', 'getQualificationDiplomaProofByID', 'removeQualificationDocuments',
      'removeQualificationDiplomaProof', 'removeFileQualification', 'removeFileProof', 'updateFileQualification',
      'updateFileProof']),

    async getData () {
      if (this.checkTypeDocument) await this.getQualificationDocumentsByID({ ...this.$route.params })
      else await this.getQualificationDiplomaProofByID({ ...this.$route.params })
    },

    deleteDocument () {
      deleteConfirmation().then(async confirmation => {
        if (confirmation) {
          const response = await this[this.sailorDocument.type_document === 16 ? 'removeQualificationDiplomaProof' : 'removeQualificationDocuments']({ ...this.$route.params })
          if (SUCCESS_CODE.includes(response?.code)) {
            this.$notification.success('deletedDocument')
            back('qualification-documents')
          }
        }
      })
    }
  }
}
</script>
